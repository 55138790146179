@font-face {
  font-family: "Neutra Text";
  src: url("./fonts/NeutraText BookSC.otf");
}
@font-face {
  font-family: "Neutra Display";
  src: url("./fonts/NeutraDisp\ Bold.otf");
}
* {
  margin: 0;
  font-family: "Neutra Text", sans-serif;
}

/* Overriding Ant Design styles */
.ant-card-head,
.ant-card-head-title {
  min-height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.ant-card-body {
  padding: 4px 0 0 0 !important;
}

.ant-card {
  margin: 5px 10px !important;
}

.ant-btn {
  border: 0 !important;
}

/* Styles for amount of items in cart */
.badge {
  height: 100;
  width: 100;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50% !important;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

#lblCartCount {
  font-size: 12px;
  color: #fff;
  /* padding: 5px; */
  vertical-align: top;
  margin-left: -10px;
}

/* Navbar fixed on top */
.fixed-top {
  position: fixed !important;
}

/* Remove border from navbar toggler, improve vertical alignment */
.navbar-toggler {
  border: none !important;
  padding: 0.5rem 0.75rem !important;
}

/* Styles for Pure React Carousel */
.buttonBack,
.buttonBack:focus {
  position: absolute;
  top: 50%;
  left: -1.5rem;
  /* transform: translateY(-10vh); */
  background-color: transparent;
  border: none;
  outline: none;
}

.buttonNext,
.buttonNext:focus {
  color: transparent;
  position: absolute;
  top: 50%;
  right: -1.5rem;
  /* transform: translateY(-10vh); */
  background-color: transparent;
  border: none;
  outline: none;
  transform: rotate(180deg);
}

.carousel__dot-group {
  position: relative;
  top: -60px;
}
.carousel__dot {
  background-color: white !important;
  opacity: 75%;
  border: none;
  padding: 0;
  margin: 4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  top: -20px;
}
.carousel__dot--selected {
  background-color: rgb(227, 126, 140) !important;
  opacity: 75%;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}

/* Overlay for mobile navbar */
.navbar-overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 1);
  overflow-y: hidden;
  transition: 0.5s;
}

.navbar-overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: left;
  /* margin-top: 30px; */
  padding-left: 15%;
  list-style-type: none;
}

.navbar-overlay a {
  padding: 8px;
  text-decoration: none;
  /* font-size: 36px; */
  /* color: #818181; */
  display: block;
  transition: 0.3s;
}

.navbar-overlay a:hover,
.navbar-overlay a:focus {
  color: #f1f1f1;
}

.navbar-overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .navbar-overlay {
    overflow-y: auto;
  }
  .navbar-overlay a {
    font-size: 20px;
  }
  .navbar-overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
