@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Overriding Bootstrap styles for centered navbar */
.navbar-nav {
  float: none;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.navbar-nav > li {
  display: inline-block;
  float: none;
}
@font-face {
  font-family: "Neutra Text", sans-serif;
  src: url("../../fonts/NeutraText\ BookSC.otf");
}

.btn-secondary {
  background-color: transparent !important;
  border: none !important;
}

/* Animation for scrolling text */
.highlighted-message-long {
  display: inline-block;
  white-space: nowrap;
  animation: floatText 9s infinite linear;
  padding-left: 100%; /*Initial offset*/
}
.highlighted-message-long:hover {
  animation-play-state: paused;
}
@keyframes floatText {
  to {
    transform: translateX(-100%);
  }
}

/* Spinner animation */
.spinnerImage {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinnerContainer {
  margin: auto;
}

/* Styles for parallax */
.parallax {
  /* The image used */
  background-image: url("../../images/paralax_2.png");

  /* Set a specific height */
  height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  padding-top: 0px;
}

/* Styles for active nav element */
.nav-link.active {
  border-bottom: 1px solid white;
}
.navbar-nav .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar-item .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Styles for product columns that expand on hover */
.product-expansible-col {
  flex: 1;
  transition: all 0.5s ease-in-out;
  height: 911px;
}
.product-expansible-col:hover {
  flex: 2;
}
.product-expansible-text {
  visibility: hidden;
}
.product-expansible-text:hover + .opacity-layer {
  /* Changes opacity layer based on text element hover*/
  opacity: 0.6;
}
.product-expansible-col:hover .product-expansible-text {
  transition: all 0.5s step-end;
  visibility: visible;
}
.opacity-layer {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.opacity-layer:hover {
  opacity: 0.6;
}

/* Styles for contact form */
.ant-form-item-control-input {
  box-shadow: rgb(0, 0, 0, 0.16) 3px 3px 4px 0px;
}
/* Remove shadows from captcha and button */
.custom-differential-wrapper
  > .ant-row.ant-form-item
  > .ant-col.ant-form-item-control
  > .ant-form-item-control-input {
  box-shadow: none;
}
.ant-btn.ant-btn-primary.ant-btn-lg {
  background-color: black;
  width: 70%;
}
.ant-btn.ant-btn-primary.ant-btn-lg:disabled {
  background-color: grey;
  color: lightgray;
  width: 70%;
}

@media screen and (max-width: 455px) {
  .captcha-button {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform-origin: center;
    -webkit-transform-origin: center;
  }
}
